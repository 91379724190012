import React, { useState, useEffect} from 'react';
import styled from 'styled-components';
import { faSearch, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const LeftBodyDiv = styled.div`
    width: calc(40% - 24px);
    border-radius: 15px;
    height: calc(100% - 4px);
    border: solid 2px #191a22;

    @media (min-width: 1600px) {
        max-width: 535px;
    }
`;

const CardBoxHeader = styled.div`
    display: flex;
    align-items: start;
    justify-content: space-between;
    width: calc(100% - 60px);
    font-weight: 300;
    height: calc(60px - 40px);
    font-size:20px;
    padding:20px 20px;
`;

const SearchBar = styled.div`
  display: flex;
  justify-content:space-between;
  align-items:center;  
  border-radius: 16px;  
  border: 3px solid #353946;
  flex-direction: row;
  width: calc(55% - 6px);
  margin-right: 20px;
`;

const SearchInput = styled.input`
  width: calc(100% - 30px);
  padding: 10px 10px;
  border:none;
  background-color: transparent;
  font-size: 15px;
  color: #f0f0f0;
  outline: none;
  
  &::placeholder {
    color: #353946;
    font-weight: 400;
    font-size: 16px;
  }
`;

const SearchIcon = styled(FontAwesomeIcon)`
  position: relative;  
  margin-right: 16px;
  font-size: 15px;  
  color: #ffff;
`;

const LeftBodyDivInside = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: start;
    flex-direction: column;
    width: calc(100% - 40px);    
    margin: 0px 20px;
    margin-top: 20px;
    padding-top: 20px;
    height: calc(100% - 104px);   
    overflow-y: scroll;
     
    &::-webkit-scrollbar {
        width: 14px;        
    }

    &::-webkit-scrollbar-track {
        background: #191a22;
        border-radius: 10px;
        margin-bottom: 10px; 
    }

    &::-webkit-scrollbar-thumb {
        background-color: #f6752a;                
        border-radius: 10px;        
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #f6752a;
    }

    &::-webkit-scrollbar-thumb:active {
        background-color: #ffcf72;
    }
`;

const CardButtonData = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    width: calc(100% - 24px); 
    min-height: 100px;
    max-height: 100px;
    border-radius: 10px;
    margin-bottom: 10px;
    background-color: ${props => props.selected ? "#f0f0f0" : "#1e202c"};     
    color: ${props => props.selected ? "#030514" : "#f0f0f0"};       
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.1s ease, transform 0.5s ease, box-shadow 0.3s ease;    

    &:hover {
        ${props => props.selected ? "" : "background-color: #191a22"};
        ${props => props.selected ? "" : "color: #999999"};
        transform: translateY(-3px); 

    &:active {
        transform: translateY(3px); 
        transition: transform 0.2s ease;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);   
    }
`;

const StatusBorderButtonData = styled.div`
    max-width: 10px;
    min-width: 10px;
    height: 100%;
    border-radius: 10px 0px 0px 10px; 
    background-color: ${props => 
        props.varcolor 
            ? props.varcolor 
            : props.hide 
                ? "transparent" 
                : props.active 
                    ? "#f5002c" 
                    : "#00bd62"
    };
`;

const BodyBorderButtonData = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    padding: 10px;
    width: calc(100% - 20px);
    height: calc(100% - 20px);
    border-radius: 0px 10px 10px 0px; 
    transition: background-color 0.3s ease, color 0.3s ease, transform 0.1s ease; 
    cursor: pointer;
    overflow-y: hidden;
    background-color: transparent;
        @media (max-width: 950px) {
        width: 100% ;
    }
`;

const ButtonDataIcon = styled(FontAwesomeIcon)`
  font-size: 42px;  

`;

const ButtonDataIcondiv = styled.div`
    display:flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 100px;

    @media (max-width: 950px) {
        display: none;
    }
`;

const BodyBorderButtonDataText = styled.div`
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  font-weight: 500;
  min-width: calc(100% - 80px);    
  max-width: calc(100% - 80px);   
  height: 100%;
  white-space: nowrap; 
  overflow: hidden;       
  z-index: 800;

      @media (max-width: 950px) {
        min-width: calc(100% - 10px);
        max-width: calc(100% - 10px) ;
        margin-left: 10px
    }
`;

const BodyBorderButtonDataTextlarge = styled.div`
    width: 100%;    
    overflow: hidden;     
    text-overflow: ellipsis;     
    white-space: nowrap; 
    height: 30px;
    font-weight: 500;
    font-size: 22px;

    @media (max-width: 950px) {
        width: 100% ;
    }
`;

const BodyBorderButtonDataTextslim = styled.div`
    font-weight:100;
    font-size: 20px;
`;

const BodyBorderButtonDataDate = styled.div`
  display: flex;
  justify-content: center;
  align-items: end;
  flex-direction: column;
  font-size: 14px;
  font-weight: 275;
  width: calc(35% - 20px);
  margin-right: 20px;
  height: 100%;

    @media (max-width: 1200px) {
        display: none;
    }
  
`;


const LeftBodyDivElement = ({SelectedButtonDataId, aButtonArray, HeaderText, DateHide, ButtonIcon, LeftBarMode, DataLoading}) => {
    
    useEffect(() => {
        WritingSearch("");
    }, [aButtonArray]);

    const [ButtonData, setButtonData] = useState(null);
     const [FiltredaButtonArray, setFiltredaButtonArray] = useState(aButtonArray);

    const ClickButtonData = (id) => {
        setButtonData(id);    
        SelectedButtonDataId(id)
    };

    const WritingSearch = (value) => {
        FilteraButtonArrayBySearch(value);
    }

    const FilteraButtonArrayBySearch = (searchText) => {
        const trimmedSearchText = searchText.trim();
    
        if (trimmedSearchText === '') {
        setFiltredaButtonArray(aButtonArray);
        return;
        }
    
        const filteredArray = aButtonArray.filter(item => {
            const searchTextLower = trimmedSearchText.toLowerCase();

            const nameMatch = item.name ? item.name.toLowerCase().includes(searchTextLower) : false;
            const plateMatch = item.plate ? item.plate.toLowerCase().includes(searchTextLower) : false;
            const dateMatch = item.date ? item.date.toLowerCase().includes(searchTextLower) : false;        
            
            return nameMatch || plateMatch ;
        });
        
    
        setFiltredaButtonArray(filteredArray);
    }; 

  return (
    <LeftBodyDiv>
        <CardBoxHeader>
            {HeaderText}
            <SearchBar>
                <SearchInput placeholder="Pesquisar"  onChange={(e) => WritingSearch(e.target.value)}  />
                <SearchIcon icon={faSearch} />
            </SearchBar>
        </CardBoxHeader>
        <LeftBodyDivInside>
            {DataLoading ? 
            <>
                Carregando...
            </>
            : 
            <>
                {FiltredaButtonArray.map(item => (
                    <CardButtonData  selected={ButtonData===item.id} key={item.id} onClick={() =>ClickButtonData(item.id)}>
                        {LeftBarMode === 0 && (
                            <StatusBorderButtonData active={item.status === 0} />
                        )}
                        {LeftBarMode === 1 && (
                            <StatusBorderButtonData hide />
                        )}
                        {LeftBarMode === 2 && (
                            <StatusBorderButtonData varcolor={item.color} />
                        )}
                        <BodyBorderButtonData >
                            <ButtonDataIcondiv>
                                <ButtonDataIcon icon={ButtonIcon} />
                            </ButtonDataIcondiv>
                            <BodyBorderButtonDataText>
                                <BodyBorderButtonDataTextlarge>{item.name}</BodyBorderButtonDataTextlarge>
                                <BodyBorderButtonDataTextslim>{item.plate}</BodyBorderButtonDataTextslim>
                            </BodyBorderButtonDataText>                        
                        </BodyBorderButtonData>  
                        {!DateHide && (
                            <BodyBorderButtonDataDate>{item.date}</BodyBorderButtonDataDate>
                        )}                                      
                    </CardButtonData>
                ))}
            </>
            }

        </LeftBodyDivInside>
    </LeftBodyDiv>
  );
};

export default LeftBodyDivElement;
