import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${props => props.MenuOpen ? 'space-between' : 'flex-end'};
  align-self: flex-start;
  padding: 5px;
  background-color: #030514;
  border-radius: 8px;
  margin: 20px;
  border: 2px solid #353946;
  color: #353946;  
  width: calc(100% - 70px);
  min-width: 40px;
  height: 40px;
`;

const SearchInput = styled.input`
  border: none;
  background-color: transparent;
  color: #fff;
  outline: none;
  margin-left: 8px;
  flex-grow: 1;
  font-size: 18px;
  width: ${props => props.MenuOpen ? '100%' : '0px'};
  display: ${props => props.MenuOpen ? 'block' : 'none'};
  &::placeholder {
    color: #777;
  }  
`;

const SearchIcon = styled(FontAwesomeIcon)`
  color: #fff;
  margin-right: 10px;  
  width: 20px;
  height: auto;
`;

const SearchBar = ({MenuOpen}) => {
  return (
    <SearchContainer MenuOpen={MenuOpen}>
      <SearchInput type="text" placeholder="Pesquisar" MenuOpen={MenuOpen} />
      <SearchIcon icon={faSearch} MenuOpen={MenuOpen} />
    </SearchContainer>
  );
};

export default SearchBar;
