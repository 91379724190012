import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faFileAlt, faUser } from '@fortawesome/free-solid-svg-icons';
import LeftBodyDivElement   from '../components/LeftBodyDivElement';
import RightBodyDivElement   from '../components/RightBodyDivElement';
import HorizontalBetweenDiv   from '../components/HorizontalBetweenDiv';

const Clients = () => {
    
    const aButtonArray = [
        { id: 1, name: 'Patricia Inês Santos Carvalho', plate: 'FKS3346' },
        { id: 2, name: 'João Silva', plate: 'JOE3233' },
        { id: 3, name: 'Pedro Carlos', plate: 'LIR3896' },
        { id: 4, name: 'Alberto Nunes', plate: 'XPO5678' },
        { id: 5, name: 'Flávio Arruda', plate: 'FJE3546' },
        { id: 6, name: 'Bruno Mendes', plate: 'LPD1234' },
        { id: 7, name: 'Matheus Oliveira', plate: 'FKS2233' },
        { id: 8, name: 'Delmiro Carrilho', plate: 'KLA4444' },
        { id: 9, name: 'Mariana Santos', plate: 'ABX2233' },
        { id: 10, name: 'Carla Souza', plate: 'XXA9911' },
        { id: 11, name: 'Felipe Costa', plate: 'GLO4567' },
        { id: 12, name: 'Luciano Pereira', plate: 'OOX9900' },
        { id: 13, name: 'Amanda Lima', plate: 'POW8822' },
        { id: 14, name: 'Bruna Lopes', plate: 'RTY5677' },
        { id: 15, name: 'Mateus Silva', plate: 'QWE3344' },
        { id: 16, name: 'Carlos Alberto', plate: 'JJK5567' },
        { id: 17, name: 'Patricia Lima', plate: 'YUI7788' },
        { id: 18, name: 'Sofia Cardoso', plate: 'LOP9900' },
        { id: 19, name: 'José Silva', plate: 'GRT5555' },
        { id: 20, name: 'Lucas Almeida', plate: 'KLS8899' },
        { id: 21, name: 'Thiago Rocha', plate: 'BLA2233' },
        { id: 22, name: 'Ana Martins', plate: 'QWX5678' },
        { id: 23, name: 'Gabriel Souza', plate: 'LPK3322' },
        { id: 24, name: 'Paulo Alves', plate: 'FJF8765' },
        { id: 25, name: 'Natália Gomes', plate: 'TRE9988' },
        { id: 26, name: 'Beatriz Ribeiro', plate: 'HGF5544' },
        { id: 27, name: 'Julia Mendes', plate: 'SSD2211' },
        { id: 28, name: 'Eduardo Fernandes', plate: 'FVD3334' },
        { id: 29, name: 'Rafael Matos', plate: 'GHJ9987' },
        { id: 30, name: 'Fernanda Costa', plate: 'JKL8877' }
    ];    

    const Fields1 = [
    { Label: 'Nome', Value: "Delmiro Paes Carrilho", Disabled: true },
    { Label: 'Data de Nascimento', Value: "13/07/2004", Disabled: true },
    { Label: 'CPF', Value: "556.443.220-01", Disabled: true },
    { Label: 'Tipo de Habilitação', Value: "A", Disabled: true },
    { Label: 'Data de Vencimento da CNH', Value: "20/09/2028", Disabled: true },
    { Label: 'Placa do Veículo', Value: "FJE3520", Disabled: true },
    { Label: 'Endereço', Value: "Rua Iguaré, 25", Disabled: true },
    { Label: 'Bairro', Value: "Tatuapé", Disabled: true },
    { Label: 'Nome do Condutor Principal', Value: "Delmiro Paes Carrilho", Disabled: true },
    { Label: 'Cidade / Estado', Value: "São Paulo / SP", Disabled: true },
    { Label: 'Valor da Cotação', Value: "R$ 340,00", Disabled: true },
    { Label: 'Valor do Serviço', Value: "R$ 38,00", Disabled: true },
    { Label: 'Comissionamento', Value: "5%", Disabled: true },
    { Label: 'Valor Total', Value: "R$ 378,00", Disabled: true },
    { Label: 'Cidade / Estado', Value: "São Paulo / SP", Disabled: true },
    { Label: 'Valor da Cotação', Value: "R$ 340,00", Disabled: true },
    { Label: 'Valor do Serviço', Value: "R$ 38,00", Disabled: true },
    { Label: 'Comissionamento', Value: "5%", Disabled: true },
    { Label: 'Valor Total', Value: "R$ 378,00", Disabled: true },
    { Label: 'Cidade / Estado', Value: "São Paulo / SP", Disabled: true },
    { Label: 'Valor da Cotação', Value: "R$ 340,00", Disabled: true },
    { Label: 'Valor do Serviço', Value: "R$ 38,00", Disabled: true },
    { Label: 'Comissionamento', Value: "5%", Disabled: true },
    { Label: 'Valor Total', Value: "R$ 378,00", Disabled: true },
    ];  

    const Fields2 = [
    { Label: 'Nome', Value: "Delmiro Paes Carrilho", Disabled: true },
    { Label: 'Data de Nascimento', Value: "13/07/2004", Disabled: true },
    { Label: 'CPF', Value: "556.443.220-01", Disabled: true },
    { Label: 'Tipo de Habilitação', Value: "A", Disabled: true },

    ];  

 const [SelectedButtonDataID, setSelectedButtonDataID] = useState(null);
 const [Fields, setFields] = useState([]);

 useEffect(() => {
    if (SelectedButtonDataID === 1) {
        setFields(Fields1);
    } else if(Fields.length > 0) {
        setFields(Fields2);
    }

}, [SelectedButtonDataID]);



 const ClickButtonData = (id) => {
    setSelectedButtonDataID(id);    
 };
  
  return (
   <HorizontalBetweenDiv>
    <LeftBodyDivElement
        SelectedButtonDataId ={ ClickButtonData}
        aButtonArray={aButtonArray}
        HeaderText={"Clientes"}      
        DateHide={true}  
        ButtonIcon={faUser}
        LeftBarMode={1}
    />   
    <RightBodyDivElement
        Fields={Fields}
        HeaderText={"Dados do Cliente"}
        FieldEmptyText={"Selecione algum Cliente para visualizar os dados!"}
    />    
   </HorizontalBetweenDiv>
  );
};

export default Clients;
