import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faShieldAlt } from '@fortawesome/free-solid-svg-icons';
import LeftBodyDivElement   from '../components/LeftBodyDivElement';
import RightBodyDivElement   from '../components/RightBodyDivElement';
import HorizontalBetweenDiv   from '../components/HorizontalBetweenDiv';

const Partners = () => {
    
    const aButtonArray = [
        { id: 1, name: 'Novo Seguros', plate: 'Seguradora', color: "#f6b433" },
        { id: 2, name: 'Darwin Seguros', plate: 'Seguradora', color: "#ff2da0" },
        { id: 3, name: 'Split Risk', plate: 'Seguradora', color: "#6bb579" }       
        ];
    
    const Fields1 = [
        { Label: 'Razão Social', Value: "Novo Seguros e Serviços", Disabled: true },
        { Label: 'Início da Parceria', Value: "13/02/2020", Disabled: true },
        { Label: 'Faturamento Total', Value: "R$ 1.250.000,00", Disabled: true },
        { Label: 'Porcentagem de Lucro', Value: "25%", Disabled: true },
        { Label: 'Vencimento do Contrato', Value: "20/09/2028", Disabled: true },
        { Label: 'Representante', Value: "Daniel", Disabled: true },
        { Label: 'Sede Corporativa', Value: "Rua Nova Brasil, 250", Disabled: true },
        { Label: 'Bairro', Value: "Morumbi", Disabled: true }
    ];
        
    const Fields2 = [
        { Label: 'Razão Social', Value: "Seguro Vida Total", Disabled: true },
        { Label: 'Início da Parceria', Value: "05/03/2021", Disabled: true },
        { Label: 'Faturamento Total', Value: "R$ 900.000,00", Disabled: true },
        { Label: 'Porcentagem de Lucro', Value: "20%", Disabled: true },
        { Label: 'Vencimento do Contrato', Value: "15/12/2027", Disabled: true },
        { Label: 'Representante', Value: "Lucas", Disabled: true },
        { Label: 'Sede Corporativa', Value: "Avenida Paulista, 1000", Disabled: true },
        { Label: 'Bairro', Value: "Bela Vista", Disabled: true }
    ];
            
    
     const [SelectedButtonDataID, setSelectedButtonDataID] = useState(null);
     const [Fields, setFields] = useState([]);
    
     useEffect(() => {
        if (SelectedButtonDataID === 1) {
            setFields(Fields1);
        } else if(Fields.length > 0) {
            setFields(Fields2);
        }

    }, [SelectedButtonDataID]);



 const ClickButtonData = (id) => {
    setSelectedButtonDataID(id);    
 };
  
  return (
   <HorizontalBetweenDiv>
    <LeftBodyDivElement
        SelectedButtonDataId ={ ClickButtonData}
        aButtonArray={aButtonArray}
        HeaderText={"Empresas"}  
        DateHide={true}   
        ButtonIcon={faShieldAlt}   
        IsImage={true}
        LeftBarMode={2}  
    />   
    <RightBodyDivElement
    Fields={Fields}
    HeaderText={"Dados da Empresa"}
    FieldEmptyText={"Selecione alguma Empresa para visualizar os dados!"}
    />    
   </HorizontalBetweenDiv>
  );
};

export default Partners;
