import React from 'react';
import styled from 'styled-components';


const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
`;

const CheckboxInput = styled.input.attrs({ type: 'checkbox' })`
  appearance: none;
  width: 24px;
  height: 24px;
  border-radius: 5px;
  background-color: transparent;
  border: 2px solid #ffcf72;
  margin-right: 10px;
  position: relative;
  cursor: pointer;
  transition: all 0.3s ease;

  &:checked {
    background-color: #ffcf72;
    border-color: #ffcf72;
  }

  &:checked::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 12px;
    height: 12px;
    border-radius: 2px;
    transform: translate(-50%, -50%) scale(0);
    transition: all 0.3s ease;
    animation: check-animation 0.3s ease forwards;
  }

  &:checked::before {
    content: '✔';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    color: #1e202c;
    font-size: 18px;
    transition: all 0.3s ease;
    animation: check-animation 0.3s ease forwards;
  }

  @keyframes check-animation {
    0% {
      transform: translate(-50%, -50%) scale(0);
    }
    50% {
      transform: translate(-50%, -50%) scale(1.2);
    }
    100% {
      transform: translate(-50%, -50%) scale(1);
    }
  }
`;

const Label = styled.label`
  color: #f0f0f0;
  font-size: 16px;
`;

const CustomCheckbox = ({ label, checked, onChange }) => (
  <CheckboxContainer>
    <CheckboxInput checked={checked} onChange={onChange} />
    <Label>{label}</Label>
  </CheckboxContainer>
);

export default CustomCheckbox;
