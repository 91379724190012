import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import customIcon from '../quotation.svg'; 

const MenuItemContainer = styled.div`
  display: flex;
  flex-direction: column; /* Ajustado para permitir a linha abaixo */
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: calc(100% - 20px);
  height: 40px;
  cursor: pointer;
  color: ${props => props.ActivePage ? "#030514 !important" : "#f0f0f0"};
  transition: background-color 0.3s ease-in-out, color 1s ease-in-out;
  background-color: ${props => props.ActivePage ? "#f0f0f0" : "transparent"};
  &:hover {
    background-color: ${props => props.ActivePage ? "#f0f0f0" : "#353946"};
  }    

`;

const MenuItemDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: calc(100% - 60px);
  padding: 5px 10px;
  cursor: pointer;
  color: ${props => props.ActivePage ? "#030514 !important" : "#f0f0f0"};
  overflow: hidden;
  transition: color 0.3s ease-in-out;
  ${MenuItemContainer}:hover & {
    color: ${props => props.ActivePage ? "#030514" : "#999999"};
  }
`;


const Icon = styled(FontAwesomeIcon)`      
  width: 20px;
  height: 26px;
  transform: translateX(-2px);
`;

const IconDiv = styled.div`
  display: flex;  
  align-items: center;
  justify-content: flex-start;
  color: ${props => props.ActivePage ? "#030514 !important" : "#ffcf72"};
  min-width: 28px;
  height: 20px;
  transition: color 0.3s ease-in-out;
  ${MenuItemContainer}:hover & {
    color: #999999;
  }
`;

const Text = styled.span`
  font-size: 20px;
  font-weight: 500;  
  margin-left: 10px;
  overflow: hidden;     
  text-overflow: ellipsis;     
  white-space: nowrap; 
`;

const Divider = styled.div`
  width: calc(100% - 20px);
  height: 2px;
  background-color: ${props => props.ActivePage ? "#f0f0f0" : "#353946"};
  transform: ${props => props.MenuOpen ? 'translate(0px, 10px)' : 'translate(0px, 14px)'};  
  transition: background-color 0.3s ease-in-out, color 1s ease-in-out;
`;

const Svgicon = styled.img`
   width: 20px;
   height: 26px;
  transform: translateX(-2px);
`;

const MenuItem = ({ icon, text, whithoutDivider, MenuOpen, ActivePage, onClick, iconsvg }) => {
  
  return (
    <MenuItemContainer ActivePage={ActivePage} onClick={onClick}>
      <MenuItemDiv ActivePage={ActivePage}>
        <IconDiv ActivePage={ActivePage}>
          {iconsvg ?             
            <Svgicon src={icon}></Svgicon>            
            :
            <Icon icon={icon} />
          }
        </IconDiv>
        {MenuOpen && (
            <Text>{text}</Text>
        )}
      </MenuItemDiv>
      {!whithoutDivider && (
            <Divider MenuOpen={MenuOpen} ActivePage={ActivePage}/>
      )}
    </MenuItemContainer>
  );
};

export default MenuItem;
