import React, { useState} from 'react';
import styled from 'styled-components';
import { faSearch, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const RightBodyDiv = styled.div`
    width: calc(60% - 24px);
    border-radius: 15px;
    height: calc(100% - 4px);
    border: solid 2px #191a22;

    @media (min-width: 1600px) {
        width: calc(100% - 585px); 
    }
`;


const CardBoxHeader = styled.div`
    display: flex;
    align-items: start;
    justify-content: space-between;
    width: calc(100% - 60px);
    font-weight: 300;
    height: calc(60px - 40px);
    font-size:20px;
    padding:20px 30px;
`;

const RightBodyDivInside = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: start;
    width: calc(100% - 40px);    
    margin: 0px 20px;
    padding-top: 20px;
    min-height: calc(100% - 104px);   
    max-height: calc(100% - 104px);       
    overflow-y: auto;
     
    &::-webkit-scrollbar {
        width: 14px;        
    }

    &::-webkit-scrollbar-track {
        background: #191a22;
        border-radius: 10px;
        margin-bottom: 10px; 
    }

    &::-webkit-scrollbar-thumb {
        background-color: #f6752a;                
        border-radius: 10px;        
    }

    &::-webkit-scrollbar-thumb:hover {
        background-color: #f6752a;
    }

    &::-webkit-scrollbar-thumb:active {
        background-color: #ffcf72;
    }
`;

const RightBodyDivInsideContent = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: start;
    width: 100% ;  
    min-height: calc(100% - 104px);   
    max-height: calc(100% - 104px);     
    height: auto;
    max-height: 100%;    
`;

const RightBodyInputMotherDiv = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    flex: 1 1 calc(50% - 40px); 
    min-width: 240px;  
    max-width: 400px;  
    margin-right: 40px;
    margin-bottom: 20px;
`;

const RightBodyInputDiv = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
`;

const RightBodyLabel = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;    
    padding-left: 20px;
    width: calc(100% - 20px);
    height: 40px;
    font-size: 18px;
    font-weight: 150;
`;

const RightBodyInput = styled.input`
    color: #f0f0f0;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    width: calc(100% - 40px);
    height: 60px;
    background-color: #1e202c;
    border: none;
    border-radius: 30px;
    padding: 0px 20px;
    font-size: 18px;
    font-weight: 500;
    outline: none;    
`;

const TextDivEmpty = styled.div`
    margin-left: 10px;
`;

const RightBodyDivElement = ({ Fields, HeaderText, FieldEmptyText}) => {
   

  return (
    <RightBodyDiv>
        <CardBoxHeader>
            {HeaderText}
        </CardBoxHeader>
        <RightBodyDivInside>
            <RightBodyDivInsideContent>
            {Fields.length > 0 ? 
                <>
                 {Fields.map(item => (
                <RightBodyInputMotherDiv>
                    <RightBodyInputDiv>
                        <RightBodyLabel>{item.Label}</RightBodyLabel>
                        <RightBodyInput value={item.Value ? item.Value : null} disabled={item.Disabled}></RightBodyInput>
                    </RightBodyInputDiv>
                </RightBodyInputMotherDiv>
                ))}
                </> 
                : 
                <>
                <TextDivEmpty>{FieldEmptyText}</TextDivEmpty>
                </>
            }
               

                
            </RightBodyDivInsideContent>
        </RightBodyDivInside>
    </RightBodyDiv>
  );
};

export default RightBodyDivElement;
