import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faTachometerAlt, faUsers, faCalendarAlt, faDollarSign, faFileAlt, faShieldAlt, faHandshake, faGear, faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import SearchBar  from '../components/SearchBar';
import MenuItem   from '../components/MenuItem';
import Dashboard  from './Dashboard';
import Quotation  from './Quotation'; 
import Proposal   from './Proposal'; 
import Clients   from './Clients'; 
import Policy   from './Policy'; 
import Partners   from './Partners'; 
import customIcon from '../quotation.svg'; 

const transitionDuration = '0.3s';

const Body = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100vh;
  background-color: #030514;
  color: #f0f0f0;
`;

const LeftMenuMotherDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #191a22;
  width: ${props => props.MenuOpen ? '300px' : '93px'};
  height: 100vh;
  transition: width ${transitionDuration} ease-in-out;
`;

const LeftMenuHeaderDivTop = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: #191a22;
  width: 100%;
  height: 100vh;
  z-index: 999;
  transition: width ${transitionDuration} ease-in-out;
`;

const LeftMenuHeaderDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-self: start;
  border-radius: 0px 0px ${props => props.MenuOpen ? '0px' : '10px'} 0px;
  background-color: #0d0f1e;
  width: ${props => props.MenuOpen ? 'calc(100% - 40px)' : '95px'};  
  height: 100px;
  padding: 0px 20px;
  transition: width ${props => props.MenuOpen ? '0.3s' : '0.5s'} ease-in-out;
`;

const MiniLogo = styled.img`
  width: 38px;
  height: auto;
  margin-left: 10px;
`;

const Logo = styled.img`
  width: 130px;
  height: auto;
  transition: opacity ${transitionDuration} ease-in-out;
  opacity: ${props => (props.MenuOpen ? 1 : 0)};
`;

const LogoDiv = styled.div`
  width:  ${props => (props.MenuOpen ? "130px" : "0px")};  
  height: auto;
  overflow: hidden;
  height: auto;
  transition: width ${transitionDuration} ease-in-out;
`;

const ArrowIcon = styled(FontAwesomeIcon)`
  font-size: 24px;  
  color: #ffcf72;  
  margin-right: 5px;
  transition: transform ${transitionDuration} ease-in-out;
  transform: ${props => (props.MenuOpen ? 'rotate(0deg)' : 'rotate(180deg)')};
`;

const BodyContainerDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width:  ${props => props.MenuOpen ? 'calc(100% - 300px)' : 'calc(100% - 93px)'};
  height: 100vh;
  transition: width ${transitionDuration} ease-in-out;
`;

const BodyContainerDivBordered = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-left: ${props => props.MenuOpen ? '0px' : '50px'};
  // border: solid 2px #191a22;   
  border-radius: 15px;
  width: ${props => props.MenuOpen ? 'calc(100% - 60px)' : 'calc(100% - 110px)'};
  height: calc(100vh - 60px);
  transition: width ${transitionDuration} ease-in-out, margin-left ${transitionDuration} ease-in-out;
`;

const MenuItems = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 100%;
  transition: all ${transitionDuration} ease-in-out;
`;

const MenuFooter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 100%;  
  position: relative;
  bottom: 30px;  
  z-index: 1000;
  transition: all ${transitionDuration} ease-in-out;
`;

const HomePage = () => {
  const [MenuOpen, setMenuOpen] = useState(true);
  const [ActivePage, setActivePage] = useState(1);
  const leftMenuRef = useRef(null);


  const handleMenuOpen = () => {
    setMenuOpen(!MenuOpen);
  };

  const handleOpenPage = (Pageid) => {
    setActivePage(Pageid);
  
    setTimeout(() => {
      const isMouseOverElement = leftMenuRef.current && leftMenuRef.current.matches(':hover');
      if (!isMouseOverElement) {
        setMenuOpen(false);
      }
    }, 1500);
  };
  

  const MenuOpenByClosed = () => {
    if (!MenuOpen) {
      setMenuOpen(!MenuOpen);  
    }    
  };

  const CloseIfClickOut = () => {
    if (MenuOpen) {
      setMenuOpen(!MenuOpen);  
    }    
  };

  // Array de objetos que define os itens do menu
  const menuItems = [
    { id: 1, icon: faTachometerAlt, text: 'Dashboard' },
    { id: 2, icon: faUsers, text: 'Clientes' },
    { id: 3, icon: faCalendarAlt, text: 'Agenda' },
    { id: 4, icon: faDollarSign, text: 'Cotações'  },
    { id: 5, icon: faFileAlt, text: 'Propostas' },
    { id: 6, icon: faShieldAlt, text: 'Apólices' },
    { id: 7, icon: faHandshake, text: 'Parceiros', withoutDivider: true },
  ];

  // Array de objetos que define os itens do rodapé do menu
  const menuFooterItems = [
    { id: 98, icon: faGear, text: 'Ajustes e Usuário' },
    { id: 99, icon: faRightFromBracket, text: 'Logout', withoutDivider: true },
  ];

  return (
    <Body>
      <LeftMenuMotherDiv MenuOpen={MenuOpen}  ref={leftMenuRef}>
        <LeftMenuHeaderDivTop MenuOpen={MenuOpen} onClick={() => MenuOpenByClosed()} >
          <LeftMenuHeaderDiv MenuOpen={MenuOpen} onClick={() => handleMenuOpen()} >
            <MiniLogo MenuOpen={MenuOpen} src={`${process.env.PUBLIC_URL}/logoimg/MiniLogo.png`} alt="MiniLogo" />
            <LogoDiv MenuOpen={MenuOpen}>
              <Logo src={`${process.env.PUBLIC_URL}/logoimg/Logo.png`} alt="Logo" MenuOpen={MenuOpen} />
            </LogoDiv>
            <ArrowIcon icon={faChevronLeft} MenuOpen={MenuOpen} />
          </LeftMenuHeaderDiv>
          <SearchBar MenuOpen={MenuOpen} />
          <MenuItems>
            {menuItems.map(item => (
              <MenuItem
                key={item.id}
                icon={item.icon}
                text={item.text}
                MenuOpen={MenuOpen}
                ActivePage={ActivePage === item.id}
                onClick={() => handleOpenPage(item.id)}
                whithoutDivider={item.withoutDivider}
                iconsvg={item.IsSvg}
              />
            ))}
          </MenuItems>
        </LeftMenuHeaderDivTop>
        <MenuFooter>
          {menuFooterItems.map(item => (
            <MenuItem
              key={item.id}
              icon={item.icon}
              text={item.text}
              MenuOpen={MenuOpen}
              ActivePage={ActivePage === item.id}
              onClick={() => handleOpenPage(item.id)}
              whithoutDivider={item.withoutDivider}
            />
          ))}
        </MenuFooter>
      </LeftMenuMotherDiv>
      <BodyContainerDiv MenuOpen={MenuOpen} onClick={() => CloseIfClickOut()}>
        <BodyContainerDivBordered MenuOpen={MenuOpen}>
          {ActivePage === 1 && (
            <Dashboard/>
            
          )}
          {ActivePage === 2 && (
            <Clients/>
            
          )}
          {ActivePage === 4 && (
            <Quotation/>
            
          )}
          {ActivePage === 5 && (
            <Proposal/>
            
          )}
          {ActivePage === 6 && (
            <Policy/>
            
          )}
          {ActivePage === 7 && (
            <Partners/>
            
          )}
        </BodyContainerDivBordered>
      </BodyContainerDiv>
    </Body>
  );
};

export default HomePage;
