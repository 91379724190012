import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faDollarSign } from '@fortawesome/free-solid-svg-icons';
import customIcon from '../quotation.svg'; 
import customIconBlack from '../quotationBlack.svg'; 
import CustomCheckbox from '../components/CustomCheckbox';

import LeftBodyDivElement   from '../components/LeftBodyDivElement';
import HorizontalBetweenDiv   from '../components/HorizontalBetweenDiv';

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 97%;
  gap: 40px; /* Espaçamento entre os cards */
`;

const CotationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: solid 2px #191a22;
  border-radius: 15px;
  padding: 20px;
  width: 28%; /* Ajuste conforme necessário */
  height: 100%;        
  overflow: hidden;
`;
const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -30px;
`;

const SearchBar = styled.div`
  position: relative;
  width: 80%;
  margin: 20px;
  margin-right: 50px;
`;

const SearchInput = styled.input`
  width: 100%;
  padding: 10px 15px;
  border-radius: 10px;
  border: 2px solid #353946;
  background-color: transparent;
  font-size: 15px;
  color: #f0f0f0;

  &::placeholder {
    color: #888;
  }
`;

const SearchIcon = styled(FontAwesomeIcon)`
  position: absolute;
  right: -10px;
  top: 50%;
  transform: translateY(-50%);
  color: #ffff;
`;

const QuoteList = styled.div`
  margin-top: 20px;
  max-height: 75vh;
  overflow-y: auto;
`;

const QuoteItem = styled.div`
  background-color: ${props => (props.isSelected ? '#ffffff' : '#1e202c')};
  color: ${props => (props.isSelected ? '#000000' : '#f0f0f0')};
  padding: 20px;
  border-left: 6px solid ${props => (props.isSelected ? '#00bd62' : '#00bd62')};
  border-radius: 10px;
  margin-bottom: 15px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;

  &:hover {
    background-color: #232838;
  }

  ${props => props.isSelected && `
    transition: all 0.3s ease;
    &:hover {
      background-color: #f0f0f0;
    }
  `}
`;

const QuoteIcon = styled.img`
  width: 40px;
  height: 50px;
  margin-right: 30px;
  margin-left: 15px;
`;

const QuoteDetails = styled.div`
  display: flex;
  flex-direction: column;
`;

const QuoteName = styled.div`
  font-size: 20px;
  color: ${props => (props.isSelected ? '#030514' : '#f0f0f0')};
  font-weight: 500;
`;

const QuoteLabel = styled.div`
  font-size: 18px;
  color: ${props => (props.isSelected ? '#030514' : '#888')};
`;

const VehicleInfoContainer = styled.div`
    width: calc(60% - 64px);
    border-radius: 15px;
    height: calc(100% - 4px);
    border: solid 2px #191a22;
    padding: 0px 20px;

    @media (min-width: 1600px) {
        width: calc(100% - 625px); 
    }
`;

const HeaderVehicle = styled.div`
    display: flex;
    align-items: start;
    justify-content: space-between;
    width: calc(100% - 60px);
    font-weight: 300;
    height: calc(60px - 40px);
    font-size:20px;
    padding:20px 10px;
`;

const TextDivEmpty = styled.div`
    margin-left: 10px;
    margin-top: 20px;
`;

const Title = styled.h2`
  color: #f0f0f0;
  font-size: 18px;
  font-weight: 300;
`;

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
`;

const Label = styled.label`
  color: #f0f0f0;
  font-size: 16px;
  margin-bottom: 8px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px 15px;
  border-radius: 20px;
  border: none;
  height: 50px;
  background-color: #1e202c;
  font-size: 15px;
  color: #f0f0f0;
  box-sizing: border-box;
  
  &::placeholder {
    color: #888;
  }

  &:input {
    text-transform: lowercase;
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
`;

const Card = styled.div`
  background-color: #1e202c;
  color: #f0f0f0;
  border: 2px solid ${props => (props.selected ? '#ffcf72' : '#353946')};
  border-radius: 10px;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 16px;

  &:hover {
    background-color: #353946;
  }

  img {
    margin-left: 20px;
    width: 50px;
    height: 50px;
  }
`;

const CardTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
`;

const CardTextModel = styled.div`
  font-weight: bold;
  margin-bottom: 5px;
`;

const CardTextYear = styled.div`
  font-size: 14px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 20px;
`;

const SelectContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Select = styled.select`
  width: 100%;
  padding: 10px 15px;
  border-radius: 20px;
  border: none;
  height: 50px;
  background-color: #1e202c;
  font-size: 15px;
  color: #f0f0f0;
  box-sizing: border-box;

  &::placeholder {
    color: #888;
  }
`;

const RightBodyDiv = styled.div`
    width: calc(60% - 24px);
    border-radius: 15px;
    height: calc(100% - 4px);
    border: solid 2px #191a22;

    @media (min-width: 1600px) {
        width: calc(100% - 585px); 
    }
`;


const CardBoxHeader = styled.div`
    display: flex;
    align-items: start;
    justify-content: space-between;
    width: calc(100% - 60px);
    font-weight: 300;
    height: calc(60px - 40px);
    font-size:20px;
    padding:20px 30px;
`;

const VehicleCard = ({ plate, selectedModel, vehicleUsage, vehicleData }) => {
  const [isNotRegistered, setIsNotRegistered] = useState(false);
  const [vehicleOptions, setVehicleOptions] = useState([]);
  const [logoUrl, setLogoUrl] = useState('');
  const [selectedCard, setSelectedCard] = useState(null);

  useEffect(() => {
    if (vehicleData && vehicleData.fipe && vehicleData.fipe.dados) {
      setVehicleOptions(vehicleData.fipe.dados);
      setLogoUrl(vehicleData.logo); // Use diretamente a URL da logo
      
      console.log(selectedModel)

      let selectedVehicle = JSON.parse(selectedModel.selectedvehicledetails)

      // Encontre o índice do modelo selecionado no array de veículos e marque-o como selecionado
      const selectedIndex = vehicleData.fipe.dados.findIndex(

        (option) => option.codigo_fipe === selectedVehicle.codigo_fipe
      );
      
      setSelectedCard(selectedIndex);
    }
  }, [vehicleData, selectedModel]);

  const handleCheckboxChange = () => {
    setIsNotRegistered(!isNotRegistered);
  };

  const handleCardClick = (index) => {
    setSelectedCard(index);
  };

  return (
    <VehicleInfoContainer>
      <HeaderVehicle>
        Informações do Veículo
      </HeaderVehicle>
      {vehicleData ? 
        <>
         <InputContainer>
        <Label>Placa do Veículo</Label>
        <Input
          type="text"
          value={plate || ''}
          disabled
        />
      </InputContainer>
      <CardContainer>
      {vehicleOptions.map((option, index) => {
        const isSelected = selectedCard === index;
       
        return (
          <Card
            key={index}
            selected={isSelected} // Verifica se o card é o selecionado
            onClick={() => handleCardClick(index)} // Marca como selecionado ao clicar
          >
            <CardTextContainer>
              <CardTextModel>{option.texto_modelo}</CardTextModel>
              <CardTextYear>{option.ano_modelo}</CardTextYear>
            </CardTextContainer>
            {logoUrl && <img src={logoUrl} alt="Logo" />} {/* Renderiza a logo usando a URL diretamente */}
          </Card>
        );
      })}
      </CardContainer>
      <CheckboxContainer>
        <CustomCheckbox
          label="Veículo não emplacado / 0 Km"
          checked={isNotRegistered}
          onChange={handleCheckboxChange}
        />
      </CheckboxContainer>
      <SelectContainer>
        <Label>Uso do Veículo</Label>
        <Select
          value={vehicleUsage || ''}
          disabled
        >
          <option value="" disabled>
            Selecione o uso do veículo
          </option>
          <option value="particular">Particular</option>
          <option value="comercial">Comercial</option>
          <option value="oficial">Oficial</option>
        </Select>
      </SelectContainer>
        </>
        :
        <>
        <TextDivEmpty>Selecione alguma cotação para visualizar os dados!</TextDivEmpty>
        </>
      }
     
    </VehicleInfoContainer>
  );
};

const Quotation = () => {
  const [quotes, setQuotes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedQuote, setSelectedQuote] = useState(null);

  const fetchQuotations = async () => {
    setLoading(true);
    try {
      const response = await fetch('http://179.94.210.7:40003/api/person/quotation');
      const data = await response.json();
      setQuotes(data); 
    } catch (error) {
      console.error('Erro ao buscar cotações:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchQuotations(); 
  }, []);

  const handleQuoteClick = (quoteId) => {   
    const selected = quotes.find(quote => quote.id === quoteId);
    setSelectedQuote(selected); 
  };

  console.log(quotes)

  return (
    <HorizontalBetweenDiv>
    <LeftBodyDivElement
        SelectedButtonDataId ={ handleQuoteClick }
        aButtonArray={quotes}
        HeaderText={"Cotações"}   
        ButtonIcon={faDollarSign}    
        LeftBarMode={0} 
        DataLoading={loading}         
        DateHide={true}   
    />   
      <VehicleCard
         plate={selectedQuote?.plate}
        selectedModel={selectedQuote}
        vehicleUsage={selectedQuote?.vehicleusage}
        vehicleData={selectedQuote ? JSON.parse(selectedQuote.vehicledata) : null}
      />
   </HorizontalBetweenDiv>
  );
};

export default Quotation;
