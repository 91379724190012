import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const transitionDuration = '0.3s';

const CardBoxDuodiv = styled.div`
    display: flex;
    align-items: ${props => props.bottom ? "flex-end" : "flex-start"};
    justify-content: space-between;
    width: 100%;
    height: 50%;    
    transition: width ${transitionDuration} ease-in-out, margin-left ${transitionDuration} ease-in-out;
`;

const CardBox = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border: solid 2px #191a22;
    border-radius: 15px;
    width: calc(48% - 4px);
    height: calc(46vh - 4px);        
    transition: width ${transitionDuration} ease-in-out, margin-left ${transitionDuration} ease-in-out;
    overflow: hidden;
`;

const CardBoxHeader = styled.div`
    display: flex;
    align-items: start;
    justify-content: space-between;
    width: calc(100% - 40px);
    font-weight: 300;
    height: calc(60px - 40px);
    font-size:18px;
    padding:20px;
`;

const CardBoxHeaderDatePickerDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 15px;
  border: none;
  color: #f0f0f0;
  width: 40%;
  max-width: 180px;
  height: 40px;
  background-color: #191a22;
  border-radius: 20px;
  position: relative;
  cursor: pointer;

  select {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0; /* Esconde o select */
    cursor: pointer;
  }
`;

const ArrowIcon = styled(FontAwesomeIcon)`
  position: absolute;
  right: 15px;
  top: 50%;
  transform: translateY(-50%) rotate(${props => (props.open ? '180deg' : '0deg')});
  transition: transform 0.3s ease;
  color: #f0f0f0;
  pointer-events: none; /* Para evitar que o ícone interfira com o clique no select */
`;

const ChartContainer = styled.div`
    
    width: calc(100% - 40px );    
    height: calc(100% - 100px );
    padding: 20px;    
`;

const TextChartDiv = styled.div`    
    display: flex;
    align-items: center; 
    justify-content: space-around; 
    width: calc(100% - 40px );    
    height: calc(50% - 50px );
    padding: 20px;    
`;

const TextChartContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: ${props => props.orange ? "#ffcf72" : "#f0f0f0"};
    font-size: 24px;    
    font-weight: 600;
`;

const TextChartHighlight = styled.div`
    font-size:74px;
    transform: translateY(20px);
`;

const TextChartSlim = styled.div`    
    font-weight: 2;
`;



const Dashboard = () => {

    const months = [
      'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
      'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
    ];
    
    const [selectedMonth, setSelectedMonth] = useState('Agosto'); 
    const [isOpen, setIsOpen] = useState(false);

    const handleSelectChange = (event) => {
      setSelectedMonth(event.target.value);
    };

    const handleClick = () => {
      setIsOpen(!isOpen);
    };

    const data1 = {
        labels: ['Fábio', 'Daniel', 'Marcos', 'Augusto', 'Celso', 'Rogério'],
        datasets: [
          {
            label: 'Vendas no Mês por Afiliado',
            data: [35, 15, 10, 25, 5, 20],
            backgroundColor: ['#f6752a', '#ffcf72', '#f6752a', '#ffcf72', '#f6752a', '#ffcf72'],
            borderRadius: 10,  // Arredonda as torres    
            barThickness: 40, // Define a largura das torres        
          },
        ],
      };

      const data2 = [
        { title: 'Apólices', subtitle: 'Documentadas', value: 78 },
        { title: 'Cotações', subtitle: 'de Clientes', value: 140 },
        { title: 'Propostas', subtitle: 'Emitidas', value: 89 },
      ];

      const data3 = {
        labels: ['Fábio', 'Daniel', 'Marcos', 'Augusto', 'Celso', 'Rogério'],
        datasets: [
          {
            label: 'Maiores comissionamentos do mes',
            data: [190, 95, 75, 125, 50, 160],
            backgroundColor: ['#c09379', '#4d3e21', '#c09379', '#4d3e21', '#c09379', '#4d3e21'],
            borderRadius: 10,  // Arredonda as torres
            barThickness: 40, // Define a largura das torres        
          },
        ],
      };      
      
      const data4 = {
        labels: ['Novo', 'Darwin', 'Split'],
        datasets: [
          {
            label: 'Vendas no Mês por Seguradora',
            data: [25, 35, 15],
            backgroundColor: ['#fafa41', '#ff3888', '#29d96c'],
            borderRadius: 10,  // Arredonda as torres
            barThickness: 100, // Define a largura das torres        
          },
        ],
      };
      
      const options = {
        responsive: true,
        maintainAspectRatio: false, // Permite ao gráfico expandir para o height definido
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: (context) => `${context.dataset.label}: ${context.raw}`, // Personaliza o tooltip
            },
          },
          datalabels: {
            display: true,
            color: 'black',           // Cor do texto
            anchor: 'end',           // Posição do texto em relação à barra (topo)
            align: 'top',            // Alinhamento do texto dentro da barra
            offset: -35,             // Offset para ajustar a posição do texto
            font: {
                size: 18,          // Tamanho da fonte
                weight: '300',    // Peso da fonte
            },
            formatter: (value) => value,
          },
        },
        scales: {
          x: {
            ticks: { 
                color: '#f0f0f0',
                font: {
                    size: 18,          // Tamanho da fonte
                    weight: '300',    // Peso da fonte
                  },
             },
          },
          y: {
            ticks: { 
                color: function(context) {
                    return context.index % 2 === 0 ? '#ffcf72' : '#f0f0f0'; // Alterna cores a cada unidade
                  },
                font: {
                    size: 18,          // Tamanho da fonte
                    weight: '300',    // Peso da fonte
                  },
             },
            grid: {
              display: true,
              color: '#353946',  // Cor das linhas horizontais
            },
            min: 0, // Valor mínimo no eixo Y
            max: 50, // Valor máximo no eixo Y
          },
        },
      };

      const options3 = {
        responsive: true,
        maintainAspectRatio: false, // Permite ao gráfico expandir para o height definido
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: false,
          },
          tooltip: {
            callbacks: {
                label: (context) => `R$ ${context.raw.toFixed(2).replace('.', ',')}`, // Formata o tooltip
            },
          },
          datalabels: {
            display: false,           
          },
        },
        scales: {
          x: {
            ticks: { 
                color: '#f0f0f0',
                font: {
                    size: 18,          // Tamanho da fonte
                    weight: '300',    // Peso da fonte
                  },
             },
          },
          y: {
            ticks: { 
                color: function(context) {
                    return context.index % 2 === 0 ? '#ffcf72' : '#f0f0f0'; // Alterna cores a cada unidade
                  },                  
                font: {
                    size: 18,          // Tamanho da fonte
                    weight: '300',    // Peso da fonte
                  },
                  callback: function(value) {
                    return `R$ ${value.toFixed(2).replace('.', ',')}`; // Formata os valores como reais
                  }
             },
            grid: {
              display: true,
              color: '#353946',  // Cor das linhas horizontais
            },
            min: 0, // Valor mínimo no eixo Y
            max: 250, // Valor máximo no eixo Y
          },
        },
      };

      const [counts, setCounts] = useState(data2.map(() => 0));

      useEffect(() => {
        const intervalDuration = 1000 / Math.max(...data2.map(item => item.value)); // Define a duração do intervalo para 1 segundo
    
        const intervalIds = data2.map((item, index) => {
          const increment = item.value / (1000 / intervalDuration); // Incremento proporcional
          let currentValue = 0;
    
          const intervalId = setInterval(() => {
            currentValue += increment;
            if (currentValue >= item.value) {
              currentValue = item.value;
              clearInterval(intervalId);
            }
            setCounts(prevCounts => {
              const newCounts = [...prevCounts];
              newCounts[index] = Math.floor(currentValue);
              return newCounts;
            });
          }, intervalDuration);
    
          return intervalId;
        });
    
        return () => intervalIds.forEach(clearInterval); // Limpa os intervalos quando o componente é desmontado
      }, []);


  return (
    <>
        <CardBoxDuodiv>
            <CardBox>
                <CardBoxHeader>Vendas no Mês por Afiliado 
                <CardBoxHeaderDatePickerDiv onClick={handleClick}>
                {selectedMonth} {/* Exibe o mês selecionado */}
                <select value={selectedMonth} onChange={handleSelectChange}>
                  {months.map((month, index) => (
                    <option key={index} value={month}>
                      {month}
                    </option>
                  ))}
              </select>
              <ArrowIcon icon={faChevronDown} open={isOpen} />
            </CardBoxHeaderDatePickerDiv></CardBoxHeader>
                <ChartContainer>
                  <Bar data={data1} options={options} />
                </ChartContainer>
            </CardBox>

            <CardBox>
            <CardBoxHeader>Emissões no Mês 
            <CardBoxHeaderDatePickerDiv onClick={handleClick}>
              {selectedMonth} {/* Exibe o mês selecionado */}
              <select value={selectedMonth} onChange={handleSelectChange}>
                {months.map((month, index) => (
                  <option key={index} value={month}>
                    {month}
                  </option>
                ))}
              </select>
              <ArrowIcon icon={faChevronDown} open={isOpen} />
            </CardBoxHeaderDatePickerDiv>
            </CardBoxHeader>
                <ChartContainer>
                <TextChartDiv>
                  <TextChartContainer orange>
                    <TextChartHighlight>{counts[0]}</TextChartHighlight>
                    <>Apólices</>
                    <TextChartSlim>Documentadas</TextChartSlim>
                  </TextChartContainer>
                </TextChartDiv>
                <TextChartDiv>
                  <TextChartContainer>
                    <TextChartHighlight>{counts[1]}</TextChartHighlight>
                    <>Cotações</>
                    <TextChartSlim>de Clientes</TextChartSlim>
                  </TextChartContainer>
                  <TextChartContainer>
                    <TextChartHighlight>{counts[2]}</TextChartHighlight>
                    <>Propostas</>
                    <TextChartSlim>Emitidas</TextChartSlim>
                  </TextChartContainer>
                </TextChartDiv>
                </ChartContainer>
            </CardBox>
        </CardBoxDuodiv>

        <CardBoxDuodiv bottom>
            <CardBox>
            <CardBoxHeader>Maiores Comissionamentos no Mês   
              <CardBoxHeaderDatePickerDiv onClick={handleClick}>
              {selectedMonth} {/* Exibe o mês selecionado */}
              <select value={selectedMonth} onChange={handleSelectChange}>
                {months.map((month, index) => (
                  <option key={index} value={month}>
                    {month}
                  </option>
                ))}
              </select>
              <ArrowIcon icon={faChevronDown} open={isOpen} />
            </CardBoxHeaderDatePickerDiv></CardBoxHeader>
                <ChartContainer>
                  <Bar data={data3} options={options3} />
                </ChartContainer>
            </CardBox>

            <CardBox>
            <CardBoxHeader>Vendas no Mês por Seguradora 
            <CardBoxHeaderDatePickerDiv onClick={handleClick}>
              {selectedMonth} {/* Exibe o mês selecionado */}
              <select value={selectedMonth} onChange={handleSelectChange}>
                {months.map((month, index) => (
                  <option key={index} value={month}>
                    {month}
                  </option>
                ))}
              </select>
              <ArrowIcon icon={faChevronDown} open={isOpen} />
            </CardBoxHeaderDatePickerDiv></CardBoxHeader>
                <ChartContainer>
                  <Bar data={data4} options={options} />
                </ChartContainer>
            </CardBox>
        </CardBoxDuodiv>
    </>
  );
};

export default Dashboard;
