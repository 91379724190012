import React from 'react';
import styled from 'styled-components';

const HorizontalBetweenDivStyled = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;     
    align-items: center;
    flex-direction: row;
`;

const HorizontalBetweenDiv = ({ children, ...props }) => {
  return (
    <HorizontalBetweenDivStyled {...props}>
      {children}
    </HorizontalBetweenDivStyled>
  );
};

export default HorizontalBetweenDiv;
